import React, { useEffect, useState } from "react";
import { Alert, Button, CustomFlowbiteTheme, Dropdown, Spinner, ToggleSwitch } from "flowbite-react";

enum Mode {
    Normal = "Normal",
    Fancy = "Fancy",
    Pirate = "Pirate",
    SciFi = "SciFi",
    Kids = "Kids",
    Mythical = "Mythical",
}

// BUG: https://github.com/themesberg/flowbite-react/issues/1360#issuecomment-2060961974
export const customThemeToggleSwitch: CustomFlowbiteTheme['toggleSwitch'] = {
    toggle: {
        base: "after:rounded-full rounded-full border group-focus:ring-4 group-focus:ring-cyan-500/25",
        checked: {
            on: "after:bg-white after:translate-x-full",
            off: "after:bg-gray-400 dark:after:bg-gray-500 border-gray-200 bg-gray-200 dark:border-gray-600 dark:bg-gray-700",
        },
    },
};

// @formatter-off
const words: Record<Mode, string[]> = {
    [Mode.Normal]: [
        "person", "do", "big", "in",
        "place", "see", "far", "on",
        "thing", "use", "hot", "but",
        "think", "want", "fast", "and",
        "make", "eat", "good", "with",
        "feel", "get", "many", "again",
        "say", "like", "real", "more",
        "go", "before", "same", "yes",
        "have", "after", "other", "no"
    ],
    [Mode.Fancy]: [
        "personage", "effectuate", "commodius", "within",
        "locale", "perceive", "distant", "atop",
        "artifact", "employ", "scalding", "notwithstanding",
        "undertaking", "desire", "swift", "besides",
        "engender", "imbibe", "superior", "supplementary",
        "esteem", "acquire", "multitudinous", "anew",
        "declaim", "espy", "nonfictional", "selfsame",
        "proceed", "prior", "same", "certainly",
        "possess", "subsequent", "disparate", "not"
    ],
    [Mode.Pirate]: [
        "matey", "do", "vast", "in",
        "place", "spy", "afar", "on",
        "piece", "employ", "scaldin'", "but",
        "think", "desire", "swift", "and",
        "make", "eat", "shipshape", "with",
        "feel", "get", "aplenty", "again",
        "parley", "love", "real", "more",
        "sail", "afore", "same", "aye",
        "sail", "afore", "same", "nye",
        "hoard", "beyond", "foreign", "nay"
    ],
    [Mode.Kids]: [
        "buddy", "do", "giant", "inside",
        "spot", "look", "far away", "up",
        "thingy", "use", "burning", "though",
        "dream", "wish", "quick", "plus",
        "make", "munch", "yummy", "with",
        "feel", "grab", "lots", "again",
        "say", "like", "true", "extra",
        "go", "before", "the same", "yup",
        "keep", "later", "different", "nope"
    ],
    [Mode.SciFi]: [
        "entity", "activate", "colossal", "aboard",
        "sector", "scan", "remote", "upon",
        "device", "utilize", "blazing", "despite",
        "ponder", "covet", "rapid", "among",
        "fabricate", "consume", "stellar", "alongside",
        "experience", "retrieve", "copious", "once more",
        "proclaim", "admire", "factual", "additional",
        "embark", "previously", "identical", "affirm",
        "retain", "subsequent", "alien", "negative"
    ],
    [Mode.Mythical]: [
        "sorcerer", "enchant", "immense", "within",
        "realm", "gaze", "beyond", "over",
        "relic", "wield", "fiery", "notwithstanding",
        "divine", "crave", "fleet", "withal",
        "create", "feast", "noble", "with",
        "sense", "seize", "abundant", "renew",
        "utter", "behold", "genuine", "further",
        "voyage", "ere", "twin", "verily",
        "hold", "following", "otherworldly", "nay"
    ]
};
debugger;
// @formatter-on
const extra: Record<Mode, string[]> = {
    [Mode.Normal]: ["hear", "hard", "can", "up"],
    [Mode.Fancy]: ["perceive", "arduous", "capable", "above"],
    [Mode.Pirate]: ["listen", "stout", "able", "aloft"],
    [Mode.Kids]: ["hear", "hard", "can", "up high"],
    [Mode.SciFi]: ["detect", "tough", "enabled", "upward"],
    [Mode.Mythical]: ["foresee", "strenuous", "empowered", "above"]
};

function PersonDoThing() {
    const [word, setWord] = useState("Person Do Thing");
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState<Mode>(Mode.Normal);
    const [hardMode, setHardMode] = useState(false);
    const [extraHints, setExtraHints] = useState(false);
    const [hints, setHints] = useState(words[mode]);


    useEffect(() => {
        setHints([...words[mode], ...(extraHints ? extra[mode] : [])]);
    }, [mode, extraHints]);

    const handleRandomize = async () => {
        if (loading) return;
        setLoading(true);
        await new Promise((res) => setTimeout(res, 1000));

        const res = await fetch(`https://persondothing.com/api/v1/word?lang=en&difficulty=${hardMode ? 1 : 0}`, {
            headers: {
                Pragma: "no-cache",
                "Cache-Control": "no-cache",
            },
        });
        const data = await res.json();

        setWord(data.value);
        setLoading(false);
    };

    return (
        <div className="grid gap-5 w-full max-w-xl mx-auto py-5 items-center justify-center bg-gray-100">
            <Alert className="grid items-center justify-center w-full">
                <h1
                    className="text-center text-5xl font-bold mt-2 transition duration-1000 capitalize"
                    style={{ opacity: loading ? 0 : 1 }}
                >
                    {word}
                </h1>
            </Alert>
            <div className="w-full grid grid-cols-4 gap-1 bg-white p-5 rounded-xl border-2 border-gray-300 shadow-md">
                {hints.map((word, i) => (
                    <div
                        className="rounded border border-gray-100 capitalize text-center pt-2 pb-1.5 text-gray-700 font-semibold leading-none text-lg"
                        key={i}
                    >
                        {word}
                    </div>
                ))}
            </div>
            <div className="w-full grid gap-2">
                <Button
                    gradientDuoTone="tealToLime"
                    className="text-center text-2xl font-bold disabled:text-gray-500 p-3 rounded-xl"
                    onClick={handleRandomize}
                    disabled={loading}
                >
                    {loading ? <Spinner className="h-5" light={true}/> : <p className="h-5 text-lg">New Word</p>}
                </Button>

                <div className="w-full grid grid-flow-col gap-2 items-center">
                    <Dropdown label={`Vobabulary: ${mode}`} inline>
                        {[Mode.Normal, Mode.Fancy, Mode.Pirate, Mode.Kids, Mode.SciFi, Mode.Mythical].map(bmode => (
                            <Dropdown.Item key={bmode} onClick={() => setMode(bmode)}>{bmode}</Dropdown.Item>
                        ))}
                    </Dropdown>
                    <ToggleSwitch label="Harder Words" checked={hardMode} onChange={(checked) => {
                        setHardMode(checked);
                        void handleRandomize();
                    }}
                                  theme={customThemeToggleSwitch}/>
                    <ToggleSwitch label="Extra Hints" checked={extraHints} onChange={setExtraHints}
                                  theme={customThemeToggleSwitch}/>
                </div>

            </div>
        </div>
    );
}

export default PersonDoThing;
